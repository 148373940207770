<template>
  <div class="container">
    <div class="pageHeader">
      <h4>{{ $consts.payTitle }} - 支付验证</h4>
      <a v-if="$consts.homePageUrl" :href="$consts.homePageUrl"> 返回首页</a>
    </div>
    <div class="panel panel-primary">
      <div class="panel-body">
        <el-form ref="form" :model="form" label-width="0" :rules="rule">
          <el-form-item prop="mchOrderNo">
            <el-input placeholder="请输入内容" v-model="form.mchOrderNo"  readonly>
              <template slot="prepend">
                <i class="el-icon-s-order" @click="upDataNo" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="subject">
            <el-input placeholder="请输入内容" v-model="form.subject"  readonly>
              <template slot="prepend">
                <i class="el-icon-shopping-cart-2" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="amount">
            <el-input placeholder="请输入支付金额" v-model="form.amount">
              <template slot="prepend">
                <i class="el-icon-coin" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="agentNo">
            <div class="box">
              <div class="label">机构号</div>
              <el-select v-model="form.agentNo" placeholder="请选择机构号" @change="changeAgentNo">
                <el-option :label="item.agentName" :value="item.agentNo" :key="item.agentNo"
                  v-for="(item, index) in agentList" />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="mchNo">
            <div class="box">
              <div class="label">商户号</div>
              <el-select :disabled="merchantList.length == 0" v-model="form.mchNo" placeholder="请选择商户号"
                @change="changeMerchantNo">
                <el-option :label="item.mchNo" :value="item.mchNo" :key="item.mchNo"
                  v-for="(item, index) in merchantList" />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="storeNo">
            <div class="box">
              <div class="label">门店</div>
              <el-select :disabled="storeList.length == 0" v-model="form.storeNo" placeholder="请选择门店"
                @change="changeStoreNo">
                <el-option :label="item.appName" :value="item.appId" :key="item.appId"
                  v-for="(item, index) in storeList" />
              </el-select>
            </div>
          </el-form-item>

          <el-form-item prop="ifCode">
            <div class="box">
              <div class="label">通道</div>
              <el-select v-model="form.ifCode" placeholder="请选择" @change="changeIfCode">
                <el-option :label="item.if_code_name" :value="item.if_code" :key="index"
                  v-for="(item, index) in ifCode_list" />
              </el-select>
            </div>
          </el-form-item>

          <div class="btn-group btn-group-justified">
            <el-button type="primary" v-for="(item, index) in payTypeList" :key="item.way_code" class="btn btn-default"
              @click="submitPay(item)">
              <div class="btn">
                <img class="icon" v-if="item.type == 'wx'" src="../assets/icon/wx.svg" alt="" />
                <img class="icon" v-if="item.type == 'ali'" src="../assets/icon/ali.svg" alt="" />
                {{ item.way_name }}
              </div>
            </el-button>
          </div>
          <div class="btn-group btn-group-justified">
            <el-button type="primary" class="btn btn-default" @click="submitUnifiedOrder('wxpay')">
              <div class="btn">
                <img class="icon" src="../assets/icon/wx.svg" alt="" />
                <div class="btn">微信支付</div>
              </div>
            </el-button>
            <el-button type="primary" class="btn btn-default" @click="submitUnifiedOrder('alilite')">
              <div class="btn">
                <img class="icon" src="../assets/icon/ali.svg" alt="" />
                <div class="btn">支付宝支付</div>
              </div>
            </el-button>
            <el-button type="primary" class="btn btn-default" @click="submitUnifiedOrder('alilite')">
              <div class="btn">
                <img class="icon" src="../assets/icon/ali.svg" alt="" />
                <div class="btn">支付宝小程序支付</div>
              </div>
            </el-button>
            <el-button type="primary" class="btn btn-default" @click="submitUnifiedOrder(null)">
              <div class="btn">支付</div>
            </el-button>
          </div>
        </el-form>
      </div>
    </div>

    <el-dialog title="扫码支付" destroy-on-close :visible.sync="qrCodeVisible" width="80%">
      <div class="qrCode">
        <div class="order_money">￥{{ form.amount }}</div>
        <img ref="qrcode" :src="qrCodeDataUrl" alt="QR Code" />
        <!-- <vue-qrcode :value="qrCodeInfo.url" :size="qrCodeInfo.size"></vue-qrcode> -->
        <el-divider></el-divider>
        <div class="order_desc">
          <div class="order_desc_item">
            <div class="label">订单名称</div>
            <div class="content">{{ form.subject }}</div>
          </div>
          <div class="order_desc_item">
            <div class="label">商户订单号</div>
            <div class="content">{{ form.mchOrderNo }}</div>
          </div>
          <div class="order_desc_item">
            <div class="label">创建时间</div>
            <div class="content">
              {{ $filters.parseTime(form.mchOrderNo.substring(2)) }}
            </div>
          </div>
        </div>
        <div class="scan">
          <svg class="icon" viewBox="0 0 1289 1024">
            <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
              d="M928.016126 543.908618 95.983874 543.908618c-17.717453 0-31.994625-14.277171-31.994625-31.994625S78.26642 479.919368 95.983874 479.919368l832.032253 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S945.73358 543.908618 928.016126 543.908618z"
              p-id="4298"></path>
            <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
              d="M832.032253 928.016126 639.892491 928.016126c-17.717453 0-31.994625-14.277171-31.994625-31.994625s14.277171-31.994625 31.994625-31.994625l191.967747 0c17.717453 0 31.994625-14.277171 31.994625-31.994625l0-159.973123c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 159.973123C928.016126 884.840585 884.840585 928.016126 832.032253 928.016126z"
              p-id="4299"></path>
            <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
              d="M351.94087 928.016126l-159.973123 0c-52.980346 0-95.983874-43.003528-95.983874-95.983874l0-159.973123c0-17.717453 14.277171-31.994625 31.994625-31.994625S159.973123 654.341676 159.973123 672.05913l0 159.973123c0 17.717453 14.449185 31.994625 31.994625 31.994625l159.973123 0c17.717453 0 31.994625 14.277171 31.994625 31.994625C383.935495 913.738955 369.658324 928.016126 351.94087 928.016126z"
              p-id="4300"></path>
            <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
              d="M127.978498 383.935495c-17.717453 0-31.994625-14.277171-31.994625-31.994625l0-159.973123c0-52.980346 43.003528-95.983874 95.983874-95.983874l159.973123 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S369.658324 159.973123 351.94087 159.973123l-159.973123 0c-17.545439 0-31.994625 14.449185-31.994625 31.994625l0 159.973123C159.973123 369.658324 145.695952 383.935495 127.978498 383.935495z"
              p-id="4301"></path>
            <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
              d="M896.021502 383.935495c-17.717453 0-31.994625-14.277171-31.994625-31.994625l0-159.973123c0-17.545439-14.277171-31.994625-31.994625-31.994625L639.892491 159.973123c-17.717453 0-31.994625-14.277171-31.994625-31.994625s14.277171-31.994625 31.994625-31.994625l191.967747 0c52.980346 0 95.983874 43.003528 95.983874 95.983874l0 159.973123C928.016126 369.658324 913.738955 383.935495 896.021502 383.935495z"
              p-id="4302"></path>
          </svg>
          <div>
            请使用{{ payType == "wx" ? "微信" : "支付宝" }}扫一扫 <br />扫描二维码完成支付
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { unifiedOrder, getAgentList, getMchList, getMchAppList,getPayWay,getOrderInfo } from "@/api/api";
import Vue from 'vue';
import {Form,FormItem,Button,Dialog,Select,Input,Option,Divider} from 'element-ui';
import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Divider);

import QRCode from "qrcode";
export default {
  components: {},
  data() {
    return {
      form: {
        mchOrderNo: `cs${new Date().getTime()}`,
        subject: "支付测试",
        amount: null,
        agentNo: "",
        mchNo: "",
        ifCode: "",
        storeNo:'',
      },
      payType: "wx",
      rule: {
        mchOrderNo: [{ required: true, message: "", trigger: "blur" }],
        subject: [{ required: true, message: "", trigger: "blur" }],
        amount: [{ required: true, message: "请输入支付金额", trigger: "blur" }],
        agentNo: [{ required: true, message: "请输入机构号", trigger: "blur" }],
        mchNo: [{ required: true, message: "请输入商户号", trigger: "blur" }],
      },
      agentList: [],
      merchantList: [],
      ifCode_list: [],
      storeList:[],
      payTypeList: [
        // { openUrl: 1, type: "ali", value: "aliLite", label: "支付宝小程序" },
        // { openUrl: 0, type: "ali", value: "aliPay", label: "支付宝网页支付" },
        // { openUrl: 1, type: "wx", value: "wxLite", label: "微信小程序" },
        // { openUrl: 0, type: "wx", value: "wxPay", label: "微信网页支付" },
      ],
      qrCodeVisible: !1,
      qrCodeInfo: {
        url: "",
        size: 200,
      },
      qrCodeDataUrl: null,
    };
  },
  async mounted() {
    // this.form = {
    //   ...this.form,
    //   subject: "支付测试",
    //   amount: parseFloat(1 / 100).toFixed(2),
    //   agentNo: "V1719208436",
    //   mchNo: "M1719209498",
    //   ifCode: "easypay",
    // };
    this.initAgent();
  },
  created() { },
  methods: {
    upDataNo() {
      this.form = {
        ...this.form,
      mchOrderNo: `cs${new Date().getTime()}`,
      };
    },
    async initAgent() {
      getAgentList()
        .then((res) => {
          this.agentList = res;
          this.merchantList=[]
          this.storeList = [];
          this.ifCode_list=[]
          this.payTypeList=[]
        })
        .catch((res) => {
          return Message.error(res.msg);
        });
    },
    async changeAgentNo(e) {
      getMchList({ agentNo: e })
        .then((res) => {
          this.merchantList = res;
          this.storeList = [];
          this.ifCode_list=[]
          this.payTypeList=[]
          this.form={
            ...this.form,
            mchNo:'',
            storeNo: "",
            ifCode: "",
          }
        })
        .catch((res) => {
          return Message.error(res.msg);
        });
    },
    changeMerchantNo(e) {
      getMchAppList({mchNo:e}).then((res)=>{
        console.log(res)
        this.storeList = res;
        this.ifCode_list=[]
        this.payTypeList=[]
          this.form={
            ...this.form,
            storeNo: "",
            ifCode: "",
          }
      }).catch((res) => {
          return Message.error(res.msg);
        });
    },
    changeStoreNo(e){
      
      getPayWay({ storeNo: e,mchNo:this.form.mchNo })
        .then((res) => {
          let ifCodeList = [];
          this.payTypeList=[]
          res.forEach((item) => {
            const codes = ["BAR"];
            // if (!codes.some((obj) => item.way_code.includes(obj))) {
            if (item.way_code.indexOf(codes[0])>-1) {
              return;
            }
            let payItem = {
              ...item,
              // way_code,
              type: item.way_code.indexOf("WX") > -1 ? "wx" : "ali",
              value: item.way_code,
            };
            let existingEntry = ifCodeList.find((obj) => obj.if_code === item.if_code);
            if (existingEntry) {
              existingEntry.payList.push(payItem);
            } else {
              ifCodeList.push({
                if_code: item.if_code,
                mch_no: item.mch_no,
                if_code_name: item.if_code_name,
                payList: [payItem],
              });
            }
          });
          this.ifCode_list = ifCodeList;
          this.form={
            ...this.form,
            ifCode: "",
          }
          console.log(ifCodeList);
        })
        .catch((res) => {
          // return Message.error(res.msg);
        });
    },
    changeIfCode(e) {
      this.payTypeList = this.ifCode_list.find((item) => item.if_code == e).payList;
      console.log(this.payTypeList);
    },

    async submitPay(type) {
      // 拉起支付
      const wayCode = type.way_code;

      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.payType = type.type;
        const params = {
          ...this.form,
          wayCode,
          body: "test",
          amount: Math.round(this.form.amount * 100),
         returnUrl: "https://pay.xinchuwl.cn/xcpay/", // 支付成功跳转地址
        };
        unifiedOrder(params)
          .then(async (res) => {
            this.upDataNo();
            console.log(res);
            // if (type.openUrl == 1) {
            // if (type.way_code.indexOf("LITE") > -1) {
            // window.location.href = res.payData;
            // } else {
            const dataUrl = await QRCode.toDataURL(res.payData, {
              margin: 2, // 设置白边大小
            });
            this.qrCodeDataUrl = dataUrl;
            this.qrCodeVisible = !0;
            // }
          })
          .catch((res) => {
            this.upDataNo();
            console.log(res);
            return Message.error(res.msg);
          });
      });
    },
    submitUnifiedOrder(type){
      if(!this.form.amount){
        return Message.error('请输入金额');
      }
      if(!this.form.agentNo){
        return Message.error('请选择代理商');
      }
      this.payType = type;
        const params = {
          ...this.form,
          payWayType:type,
          body: "test",
          amount: Math.round(this.form.amount * 100),
          expiredTime:2,
          returnUrl: "https://pay.xinchuwl.cn/xcpay/", // 支付成功跳转地址
        };
        unifiedOrder(params)
          .then(async (res) => {
            this.upDataNo();
            console.log(res);
            // if (type.openUrl == 1) {
            // if (type.way_code.indexOf("LITE") > -1) {
            // window.location.href = res.payData;
            // } else {
            const dataUrl = await QRCode.toDataURL(res.payData, {
              margin: 2, // 设置白边大小
            });
            this.qrCodeDataUrl = dataUrl;
            this.qrCodeVisible = !0;
            // }
          })
          .catch((res) => {
            this.upDataNo();
            console.log(res);
            return Message.error(res.msg);
          });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 640px;
}

.pageHeader {
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-weight: 600;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  a {
    font-size: 22px;
    text-decoration: none;
    color: #409eff;
  }
}

@media (max-width: 576px) {
  .pageHeader {
    h4 {
      font-size: 18px;
    }

    a {
      font-size: 16px;
    }
  }
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-primary {
  border-color: #337ab7;
}

::v-deep .panel {
  .el-form-item {
    margin-bottom: 18px;

    .el-form-item__content {
      line-height: 30px;

      .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.box {
  display: flex;
  width: 100%;
  border: solid 1px #dcdfe6;
  border-radius: 4px;

  .label {
    width: 80px;
    align-items: center;
    border-radius: 4px;
    background-color: #f5f7fa;
    color: #909399;
    display: inline-flex;
    justify-content: center;
    min-height: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  ::v-deep .el-select {
    flex: 1;
    border-width: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    .el-input--suffix .el-input__inner {
      border-width: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .el-input.is-disabled .el-input__inner{
      border-color:#DCDFE6;
    }

    .el-input__icon {
      line-height: 30px;
    }
  }

  ::v-deep .el-select__wrapper {
    border-width: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.btn {
  align-items: center;
  display: inline-flex;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}

.panel-body {
  padding: 15px;
}

.btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  flex: 1;
}

.btn-group-justified {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  justify-content: space-between;
}

.btn-default {
  margin: 0;
  margin-bottom: 8px;
  width: 48%;
  padding: 6px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  background-color: #fff;
  border: solid 1px #ccc;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  text-align: center;
}

::v-deep {
  .el-dialog__header {
    padding: 15px 10px 10px 10px;
  }

  .el-dialog__body {
    padding: 0 10px 20px;
  }

  .el-divider--horizontal {
    margin: 10px;
  }
}

.qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: auto;

  .order_money {
    font-size: 2em;
  }

  img {
    width: 200px;
  }

  .order_desc {
    width: 100%;

    .order_desc_item {
      width: 100%;
      display: flex;
      font-size: 1em;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .scan {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
